import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  width: 650px;
  height: 230px;
  position: relative;
  opacity: 0;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.playball-outro {
      opacity: 0;
    }
  }
`;